import { APIFilter } from '@/utils/api'
import { SOBRE, INTEGRACION } from '@/utils/consts'
import _ from '@/utils/lodash'

export default {
  async entregarSobre (Vue, formData, idsobre) {
    // Esto debería hacer lo mismo que TFDatosDocumentacion.EntregarSobre
    let apiCalls = []
    const apiFilter = new APIFilter()
    apiFilter.addExact('idsobre', idsobre)
    apiFilter.addGT('estado', 0)
    apiCalls.push({ name: 'selectSobre', method: 'sobre.select', params: { filter: apiFilter } })
    apiCalls.push({
      name: `sobreDameIntegracion`,
      method: 'sobre.DameIntegracionSobre',
      params: { idsobre: idsobre },
    })
    const resp = await Vue.$api.batchCall(apiCalls)
    const idintegracion = resp.data.sobreDameIntegracion.result
    if (!idintegracion) {
      throw new Error('No hay integración definida para el sobre.')
    }
    const sobre = resp.data.selectSobre.result.dataset[0]
    let apiCallsCierreSobre = []
    if (sobre.estado = SOBRE.estados.abierto) {
      // cierra el sobre - actualiza los destinatarios
      apiCallsCierreSobre.push({ name: 'cerrarSobre', method: 'sobre.cerrar', params: { values: { idsobre: idsobre }}})
      // genera los documentos
      apiCallsCierreSobre.push({ name: 'sobreGenerar', method: 'sobre.generar', params: { idsobre: idsobre, background: false, }})
    }
    // consulto los grupos después de cerrar porque mete los previstos
    apiCallsCierreSobre.push({ name: 'selectGruposDestinatarios', method: 'sobreDestinatario.selectGruposDestinatarios', params: { filter: apiFilter } })
    // En el call de apiCallsCierreSobre me traigo la información de la integración para obtener el remitente
    // Por ahora sólo me interesa el remitente
    const apiFilterDatoIntegracion = new APIFilter()
      .addExact('idintegracion', idintegracion)
      .addGT('estado', 0)
    apiCallsCierreSobre.push({ name: 'selectDatoIntegracion', method: 'datoIntegracion.select', params: { filter: apiFilterDatoIntegracion } })
    const respCierreSobre = await Vue.$api.batchCall(apiCallsCierreSobre)
    let apiCallsDestinatarios = []
    const remitente = _.find(respCierreSobre.data.selectDatoIntegracion.result.dataset, { clasificacion: INTEGRACION.datos.remitente })
    for (const [_, destinatario] of respCierreSobre.data.selectGruposDestinatarios.result.dataset.entries()) {
      apiCallsDestinatarios.push({
        method: 'sobre.insSobreEnvio',
        params: {
          values: {
            idsobre: idsobre,
            idintegracion: idintegracion,
            mensaje_con_firma: sobre.mensaje_con_firma,
            mensaje_asunto: formData.asunto,
            mensaje: formData.body,
            remitente: remitente.valor,
            notifica_comercial: destinatario.notifica_comercial,
            notifica_sat: destinatario.notifica_sat,
            notifica_admon: destinatario.notifica_admon,
          },
        },
      })
    }
    const respCallsDestinatarios = await Vue.$api.batchCall(apiCallsDestinatarios)
    let apiCallsEnvios = []
    const nameEnvioEnviar = 'sobreEnvioEnviar_'
    for (const [_, sobreEnvio] of Object.entries(respCallsDestinatarios.data)) {
      if (sobreEnvio.result) {
        apiCallsEnvios.push({
          name: `${nameEnvioEnviar}${sobreEnvio.result}` ,
          method: 'sobreEnvio.enviar',
          params: {
            idsobre_envio: sobreEnvio.result,
            background: false,
          },
        })
      }
    }
    let respEnvios = []
    respEnvios = await Vue.$api.batchCall(apiCallsEnvios, {silentErrors: true})
    let apiCallsResultadoEnvios = []
    let envioCorrecto = true
    for (const [_, envio] of Object.entries(respEnvios?.data)) {
      const idosbreEnvio = parseInt(envio.name.substring(nameEnvioEnviar.length))
      // Actualizar sobre envío con el resultdo
      // Si el resultado es malo, tengo que indicarlo en el campo respuesta
      let values = {}
      if ('error' in envio) {
        values = { idsobre_envio: idosbreEnvio, enviado: false, respuesta: envio.error.message }
        envioCorrecto = false
      } else {
        values = { idsobre_envio: idosbreEnvio, enviado: envio.result }
      }
      apiCallsResultadoEnvios.push({ name: 'updateSobreEnvio', method: 'sobreEnvio.update', params: { values: values }})
    }
    await Vue.$api.batchCall(apiCallsResultadoEnvios)
    return envioCorrecto
  },
}
